<template>
  <el-dialog :visible.sync="isShow" custom-class="custom-dialog">
    <div class="real-name">
      <div class="real-name-title">{{ $t("身份验证") }}</div>
      <el-form label-positon="top" class="real-name-form" ref="form" :model="form" :rules="rules" v-if="form">
        <div v-for="item in initFormList(data)" :key="item.name">
          <div class="introduce html" v-if="$t(item[0]?.introduce)" v-html="$t(item[0]?.introduce)"></div>

          <!-- 邮政编码 -->
          <el-form-item :prop="item.key"
            :label="item.introduce ? `${$t(item.name)} (${item.introduce})` : $t(item.name)"
            v-if="item.key === 'Postal_Code'">
            <div class="input-box">
              <el-input :placeholder="$t('请输入' + item.name)" v-model="form[item.key]" inputmode="numeric"
                :disabled="userInfo.user_verify_status == 2 || userInfo.user_verify_status == 1"
                v-mask="'###-####'"></el-input>

              <el-button :loading="addressLoading" class="button_blue btn" size="mini">{{ $t("地址检索") }}</el-button>
            </div>
          </el-form-item>

          <!-- 城市 -->
          <el-form-item v-else-if="item.key === 'City'" v-show="form[item.key]" :prop="item.key"
            :label="item.introduce ? `${$t(item.name)} (${item.introduce})` : $t(item.name)">
            <el-input :placeholder="$t('请输入' + item.name)" v-model="form[item.key]" :disabled="true"></el-input>
          </el-form-item>

          <!-- 地址 -->
          <el-form-item v-else-if="item.key === 'Address_Details'" v-show="form['City']" :prop="item.key"
            :label="item.introduce ? `${$t(item.name)} (${item.introduce})` : $t(item.name)">
            <el-input :placeholder="$t('请输入' + item.name)" v-model="form[item.key]"
              :disabled="userInfo.user_verify_status == 2 || userInfo.user_verify_status == 1"></el-input>
          </el-form-item>

          <!-- 其他表单 -->
          <el-form-item :label="item.introduce ? `${$t(item.name)} (${item.introduce})` : $t(item.name)"
            v-else-if="item.type === 'text'" :prop="item.key">

            <!-- 下拉 -->
            <el-select style="width: 100%;" v-if="item.dev_type === 'select'" v-model="form[item.key]"
              :placeholder="$t('请选择')" :disabled="userInfo.user_verify_status == 2 || userInfo.user_verify_status == 1">
              <el-option v-for="item in item.options" :key="item" :label="$t(item)" :value="$t(item)"></el-option>
            </el-select>

            <!-- 日期 -->
            <el-date-picker v-else-if="item.dev_type === 'date'" style="width: 100%;" format="MM-dd-yyyy"
              value-format="yyyy-MM-dd" v-model="form[item.key]" :placeholder="$t('请选择')"
              popper-class="el-date-picker-content" class="date-picker"
              :disabled="userInfo.user_verify_status == 2 || userInfo.user_verify_status == 1"></el-date-picker>

            <!-- 文本 -->
            <el-input v-else :placeholder="$t('请输入' + item.name)" v-model="form[item.key]"
              :disabled="userInfo.user_verify_status == 2 || userInfo.user_verify_status == 1"></el-input>

          </el-form-item>

          <div v-else class="uploader-list">
            <el-upload class="uploader" :action="action" :show-file-list="false"
              :on-change="(e) => change(e, imgItem.key)" :disabled="loadings[imgItem.key] || !isUpload"
              :on-success="(e) => uploadSuccess(e, imgItem.key)" :headers="uploadHeader" v-for="imgItem, index in item"
              :key="index">
              <div class="card-warp" v-loading="loadings[imgItem.key]">
                <div class="card-content">
                  <img :src="form[imgItem.key]" class="upload-img" v-if="form[imgItem.key]" key="upload-img">
                  <img src="@/assets/images/camera.png" class="upload-icon" key="upload-icon" v-else>
                </div>
              </div>
              <span class="upload-tip"><span style="color: #F56C6C;">*</span> {{ $t(imgItem.name) }}</span>
            </el-upload>
          </div>

        </div>

      </el-form>

      <div class="status" v-if="userInfo.user_verify_status == 2">
        <img src="@/assets/images/id-icon.png" alt="" class="id-icon">
        <span>{{ $t("已认证") }}</span>
      </div>
      <div class="status status-yellow" v-if="userInfo.user_verify_status == 1">
        <img src="@/assets/images/id-icon-yellow.png" alt="" class="id-icon">
        <span>{{ $t("正在审核中，请耐心等待") }}</span>
      </div>
      <div class="status status-red" v-if="userInfo.user_verify_status == 3">
        <img src="@/assets/images/id-icon-red.png" alt="" class="id-icon">
        <span>{{ $t("审核失败，重新认证") }}</span>
      </div>
      <el-button class="button_blue button_fullwidth button_submit" @click="submit" v-if="isUpload"
        :loading="btnLoading">{{
          $t("立即认证") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import addressSearch from '@/utils/addressSearch.js'
import { user_add_verify_api, user_verify_vonfig_api } from "@/api/user"
export default {
  data() {
    return {
      isShow: false,
      uploadHeader: { usertoken: "", usertokenkey: "" },
      action: this.$helper.getApiUrl() + '/upload/img',
      btnLoading: false,
      loadings: {},
      data: [],
      addressLoading: false,
      form: null,
      rules: {}
    }
  },
  computed: {
    // 是否能够上传
    isUpload() {
      return this.userInfo.user_verify_status == 0 || this.userInfo.user_verify_status == 3
    },
    userInfo() {
      return this.$store.state.userInfo || {}
    }
  },
  methods: {
    async addressSearch(value) {
      this.addressLoading = true
      const address = await addressSearch(value)
      if (address) {
        this.form.City = address.join("")
      }
      this.addressLoading = false
      return address
    },
    async show() {
      let { token, token_key } = this.$helper.get("tokenInfo");
      this.uploadHeader.usertoken = token;
      this.uploadHeader.usertokenkey = token_key;
      await this.getData()
      this.isShow = true;
    },
    async getData() {
      const { data: res } = await user_verify_vonfig_api()
      if (res.code == 1) {
        this.data = res.data
        this.initForm(res.data)
      }
    },
    initFormList(list) {
      const arr = []
      list.forEach(item => {
        if (item.key === "Sex") {
          item.dev_type = "select"
          item.options = ["男", "女"]
        }
        if (item.key === "Birthday") {
          item.dev_type = "date"
        }
        if (item.type === 'img') {
          const lastData = arr[arr.length - 1]
          if (lastData.length === 1) {
            lastData.push(item)
          } else {
            arr.push([item])
          }
        } else {
          arr.push(item)
        }
      })
      return arr
    },
    initForm(list) {
      const form = {}
      list.forEach(item => {
        form[item.key] = item.value
        if (item.key === "Postal_Code") {
          this.rules[item.key] = [
            {
              validator: async (rule, value, callback) => {
                value = value.replace(/\D/g, '')
                if (!value.length) callback(new Error(this.$t('请输入' + item.name)))
                if (value.length !== 7) callback(new Error(this.$t('邮政编码格式7位数字')))
                if (value.length === 7) {
                  const res = await this.addressSearch(value)
                  if (!res) callback(new Error(this.$t('未检索出地址!')))
                } else {
                  this.form.City = ""
                  this.form.Address_Details = ""
                  callback()
                }
              },
              trigger: 'change'
            }
          ]
        } else {
          this.rules[item.key] = [{ required: true, message: this.$t('请输入' + item.name) }]
        }
      })
      this.form = form
    },
    async submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return
        this.data.forEach(item => item.value = this.form[item.key])
        for (let i = 0; i < this.data.length; i++) {
          const item = this.data[i];
          if (!item.value?.trim()) {
            this.errorTips(this.$t(item.name) + this.$t("不能为空"))
            return;
          }
        }
        this.btnLoading = true
        const { data: res } = await user_add_verify_api({ content: JSON.stringify(this.data) })
        this.btnLoading = false
        if (res.code == 1) {
          this.$message({
            showClose: true,
            message: this.$t("提交成功，等待后台审核"),
            type: "success",
          });
          // 更新状态
          this.$store.commit("$vuexUserInfo", {
            ...this.userInfo,
            user_verify_status: 1
          })
          this.isShow = false
        } else {
          this.errorTips(res.msg)
        }
      })
    },
    findData(key) {
      return this.data.find(item => item.key === key)
    },
    change(e, key) {
      this.loadings[key] = (e.status !== 'success')
      this.$forceUpdate();
    },
    uploadSuccess(e, key) {
      if (e.code == 1) {
        this.form[key] = e.data.file
        this.$forceUpdate();
      }
    },
  }
}
</script>

<style scoped lang="less" src="@/assets/css/realNameAuthentication.less"></style>